.cardsGrid
    display: grid
    grid-template-columns: repeat(2, 360px)
    grid-template-rows: repeat(2, 240px)
    gap: 50px 40px
    justify-content: center
    margin-top: 60px
    .card
        width: 100%
        height: 100%
        background-color: #fff
        background-repeat: no-repeat
        background-size: contain
        background-position: top center
        box-shadow: 0px 0px 12px var(--color)
        border-radius: 10px
        display: flex
        align-items: flex-end
        overflow: hidden
        cursor: pointer
        background-size: 72%
        background-position: 50% 25%
        .content
            width: 100%
            border-top: 1px solid #E0E0E0
            height: 47px
            text-align: center
            background: #F8F8F8
            padding: 5px
            cursor: hover
            p
                font-weight: 600
            span
                font-size: 13px
                line-height: 13px
        &.inactive
            filter: grayscale(100%)
