.container
    margin-top: 32px
    display: flex
    justify-content: space-evenly
    position: relative
    border-bottom: 1px solid #E0E0E0
    padding: 30px 0
    .data
        width: 420px
        p
            font-weight: 600
            text-align: center
        display: flex
        flex-direction: column
        gap: 10px
        .twoItems
            display: flex
            gap: 15px
        .other
            width: 420px
            position: absolute
            bottom: -200px
            display: flex
            flex-direction: column
            gap: 28px
    .side
        .title
            font-weight: 600
            text-align: center
            margin-bottom: 10px
        .card
            width: 540px
            background: #FBFBFB
            border: 1px solid #E0E0E0
            border-radius: 4px
            display: flex
            flex-direction: column
            gap: 20px
            padding: 12px 24px
            .print
                width: 100%
                display: flex
                flex-direction: column
                gap: 10px
                align-items: center
                & > div
                    display: flex
                    gap: 5px
                    font-size: 13px
                    line-height: 18px
                    color: #737476
                    & > select
                        width: 135px
                        border-radius: 4px
                button
                    padding: 8px 33px
                    margin-top: 20px
                    font-weight: bold
                    font-size: 14px
                    line-height: 19px
                    text-transform: none
            .sticker
                margin: 18px 0
                align-self: center
                background: #fff
                padding: 12px
                display: flex
                gap: 24px
                align-items: center
                .info
                    min-width: 180px
                    .company
                        text-align: center
                        border-bottom: 1px solid #000
                        padding-bottom: 5px
                        margin-bottom: 5px
                        p
                            font-weight: 600
                        span
                            display: block
                            font-size: 10px
                            line-height: 12px
                    .item
                        display: flex
                        gap: 5px
                        width: 100%
                        height: 15px
                        font-size: 10px
                        line-height: 15px
                        align-items: center
                        span
                            font-size: 12px
                            font-weight: 700
                        p
                            width: 40px
                            font-weight: 400
                            text-align: right
                .qr
                    display: flex
                    flex-direction: column
                    font-size: 32px
                    line-height: 32px
                    font-weight: 600
                    height: 162px
                    .text
                        height: 32px
                        display: flex
                        justify-content: space-between
                        text-align: center
                        .S
                            text-align: center
                            width: 24px
                        .L
                            text-align: center
                            width: 47px
                        .B
                            height: 40px
                            text-align: center
                            width: 40px
                            background: #000
                            color: #fff
                    .titles
                        display: flex
                        justify-content: space-between
                        margin: 10px 0
                        p
                            text-transform: uppercase
                            font-weight: 600
                            font-size: 14px
                            line-height: 12px
                            text-align: center
                        .S
                            width: 24px
                        .L
                            width: 47px
                        .B
                            width: 40px
                    &.ware
                        font-size: 40px
                        line-height: 40px
                        height: auto
                        .text
                            gap: 5px
            .hItem
                display: flex
                p
                    &:nth-child(1)
                        width: 150px
                        cursor: pointer
                        color: #00e
                    &:nth-child(2)
                        width: 150px
                        //margin-left: auto
                    &:nth-child(3)
                        width: 70px
                        //margin-left: 30px
                    &:nth-child(4)
                        width: 90px
                    &:nth-child(5)
                        width: 90px
                        
        .manual
            margin: 24px 0
            display: flex
            justify-content: center
            flex-direction: column
            align-items: center
            gap: 3px
            .maskedInput
                outline: none
                width: 193px
                height: 42px
                background: #FFFFFF
                border: 1px solid #E0E0E0
                border-radius: 4px
                font-size: 13px
                line-height: 18px
                color: #999999
                padding: 0 15px
            .placeholdertitles
                font-weight: 600
                font-size: 11px
                line-height: 15px
                color: #737476
                letter-spacing: 0.3px