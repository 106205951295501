*
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    box-sizing: border-box
    margin: 0
    padding: 0

body
    font-size: 13px
    line-height: 18px
    background: #F8F8F8

a
    text-decoration: none

.App
    padding-top: 60px

.Timg
    max-height: 36px
    img
        max-height: 36px
.Timg2
    max-height: 58px
    margin-bottom: 5px
    margin-top: 5px
    img
        max-height: 58px
        margin-right: 6px
        
.editdelete
    cursor: pointer
    display: inline-flex
    align-items: center
    justify-content: center
    width: 30px
    height: 30px
    border-radius: 5px
    background: #fff
    transition: 0.2s all ease
    position: relative
    &.delete
        margin: 0 20px
    .help
        display: inline-block
        background: rgba(0, 0, 0, 0.65)
        border-radius: 5px
        transition: 0.2s all ease
        font-weight: 600
        font-size: 11px
        line-height: 13px
        color: #fff
        padding: 8px 13px
        position: absolute
        top: -35px
        z-index: 100
        opacity: 0
        pointer-events: none
        text-align: center
    &:hover
        background: #eee
        .help
            opacity: 1

.checkbox
    margin-top: 5px
    width: 16px
    height: 16px
    background: #fff
    border: 1px solid #A4A4A4
    border-radius: 2px

.ebayStatus
    height: 24px
    padding: 3px 12px
    border-radius: 5px
    display: inline-block
    &.active
        background: #EEFFE8
        color: #00831D
    &.banned
        background: #FFE8E8
        color: #850202
        cursor: pointer
        position: relative
        &::before
            content: 'Generate auth URL'
            display: inline-block
            height: 24px
            line-height: 24px
            border: 1px solid #000
            background: #fff
            pointer-events: none
            position: absolute
            top: -30px
            right: -30px
            opacity: 0
            padding: 0 5px
            color: #000
            transition: all 0.2s ease
            transition-delay: 1500ms
        &:hover
            &::before
                opacity: 1


.css-1v2lvtn-MuiPaginationItem-root
   //  display: none

.dndButton
    background: #D0DBFF
    border-radius: 5px
    font-size: 13px
    line-height: 18px
    color: #313131
    padding: 6px 28px
    display: inline-block

.ebayTitle
    text-align: left
    max-width: 850px
    min-width: 300px
