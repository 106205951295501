.container
    margin-top: 32px
    display: flex
    justify-content: space-evenly
    position: relative
    padding: 30px 0
    .card
        padding: 34px 24px
        display: flex
        flex-direction: column
        gap: 24px
        background: #fff
        width: 780px
        border-radius: 5px
        border: 1px solid #E0E0E0
        .header
            display: flex
            align-items: center
            justify-content: space-between
            p
                font-size: 20px
                line-height: 27px
//        .content
.modal
    z-index: 101
    min-width: 350px
    .header
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 24px
        height: 50px
        background: #7480AA
        border-radius: 5px 5px 0px 0px
        p
            font-size: 16px
            line-height: 22px
        .close
            cursor: pointer
            display: flex
            align-items: center
            justify-content: center
            width: 30px
            height: 30px
            background: rgba(255, 255, 255, 0.2)
            border-radius: 5px
    .content
        color: #000
        background: #FFF
        border: 1px solid #E0E0E0
        border-radius: 0 0 5px 5px
        padding: 24px 34px
        .flexed
            display: flex
            flex-direction: column
            align-items: center
        .delete
            display: flex
            gap: 20px
            font-size: 13px
            line-height: 18px
            .text
                display: flex
                flex-direction: column
                justify-content: space-evenly
                span
                    text-transform: uppercase
            .imgWrapper
                width: 148px
                height: 148px
                border: 1px solid #E0E0E0
                border-radius: 5px
                background-size: contain
                background-position: center
                background-repeat: no-repeat
        form
            color: #000
            display: flex
            align-items: center
            flex-direction: column
            &.user
                gap: 10px
                align-items: flex-start
            & > p
                font-size: 13px
                line-height: 18px
                color: #313131
            .flexed
                display: flex
                gap: 10px
                flex-direction: row
                & > div
                    display: flex
                    flex-direction: column
            .flexed2
                display: flex
                gap: 34px
                & > div
                    gap: 15px
                    display: flex
                    flex-direction: column
                    .upload
                        cursor: pointer
                        width: 148px
                        height: 148px
                        background: #FFF
                        border: 1px solid #E0E0E0
                        border-radius: 5px
                        display: flex
                        flex-direction: column
                        justify-content: flex-end
                        background-position: center
                        background-repeat: no-repeat
                        background-size: contain
                        .grayBtn
                            height: 24px
                            background: #e6e6e6
                            font-weight: 600
                            font-size: 13px
                            line-height: 24px
                            color: #858585
                            text-align: center
        .submit
            border: 0
            outline: none
            cursor: pointer
            padding: 8px 28px
            margin-top: 24px
            background: #EBFAFF
            border-radius: 5px
            font-weight: 600
            font-size: 13px
            line-height: 18px
            color: #243A5E
            align-self: center
            transition: 0.2s ease all
            &.red
                background: #ffebeb98
                color: #840000
                &:hover
                    background: #FFEBEB
            &:hover
                background: #D3F4FF