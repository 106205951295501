.header
    position: fixed
    top: 0
    left: 0
    right: 0
    height: 60px
    background: #fff
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
    display: flex
    justify-content: space-between
    align-content: center
    padding: 0 2%
    z-index: 5
    .me
        display: flex
        align-items: center
        gap: 24px
        position: relative
        .logo
            max-height: 32px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
            img
                max-height: 32px
        .dropDown
            display: none
            flex-direction: column
            position: absolute
            right: 0
            bottom: 0
            background: #fff
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15)
            font-weight: 600
            font-size: 13px
            line-height: 30px
            color: #5E5E5E
            cursor: pointer
            width: 150px
            & > div
                padding-left: 20px
                border: 1px solid #E0E0E0
                &:hover
                    background: #eee
        &:hover
            .dropDown
                display: flex

    .down
        position: fixed
        left: 0
        top: 60px
        right: 0
        height: 32px
        background: #F2F2F2
        display: flex
        align-items: center
        justify-content: center
        gap: 24px
        padding: 6px 0
        .separator
            width: 1px
            background: rgba(94, 94, 94, 0.5)
            height: 100%
        .menuBtn
            cursor: pointer
            padding: 3px 10px
            color: #5E5E5E
            font-weight: 600
            border-radius: 5px
            &.active
                background: #5D9EFF
                color: #fff
