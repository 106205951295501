.input
    width: 260px
    height: 40px
    background: #F9F9F9
    border: 1px solid #E4E4E4
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    input
        outline: none
        border: 0
        padding-left: 55px
        font-size: 13px
        line-height: 18px
        color: #919191
        line-height: 40px
        background: transparent
        width: 100%
    img
        position: absolute
        left: 20px
        top: 50%
        transform: translateY(-50%)