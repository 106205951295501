.card
    width: 300px
    height: 305px
    margin: 165px auto 0
    background: #FFFFFF
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    display: flex
    align-items: center
    flex-direction: column
    img
        width: 50px
        height: 50px
        margin: 40px 0 24px
    form
        padding: 0 50px 40px
        display: flex
        flex-direction: column
        gap: 15px