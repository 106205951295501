.tableWrapper
    overflow: auto
    width: 100%
    .table
        width: 100%
        thead
            width: 100%
            height: 40px
            background: #1F67D30F
            color: #243A5E
            overflow: hidden
            width: 100%
            th
                text-align: center
                &:nth-child(1)
                    border-radius: 5px 0 0 5px
                    padding-left: 15px
                &:nth-last-child(-n + 1)
                    border-radius: 0 5px 5px 0
                    padding-right: 10px
                    img
                        cursor: pointer
                .header
                    padding: 6px
                    border-radius: 5px
                    display: inline-flex
                    gap: 7px
                    align-items: center
                    transition: 0.3s all ease
                    &.active
                        &:hover
                            background: #D0DBFF
        tbody
            tr
                background: #fff
                min-height: 40px
                height: 40px
                td
                    text-align: center
                    border-bottom: 1px solid #d8d8d8
                    padding-left: 4px
                    padding-right: 4px
                    &:nth-child(1)
                        //padding-left: 15px
                    a
                        color: #3F51B5
                    span
                        cursor: pointer
    .pagination
        margin: 30px auto
        display: flex
        justify-content: space-between
        align-items: center
        .dummy
            width: 115px
        .perPage
            width: 115px
            select
                width: 100%
                height: 30px
                background: #fff
                border: 1px solid #E4E4E4
                border-radius: 5px
                font-size: 12px
                line-height: 16px
                color: #767676
    &.white
        .table
            thead
                color: #313131
                background: transparent
            tbody
                tr
                    &:nth-child(1)
                        td
                            border-top: 1px solid #F1F1F1
    &.beige
        .table
            tbody
                tr
                    td
                        border-top: 1px solid #fff
                        border-bottom: 1px solid #fff
                        background: #F7F7F7
                        &:nth-child(1)
                            border-radius: 5px 0 0 5px
                        &:nth-last-child(1)
                            border-radius: 0 5px 5px 0
                            
                            
