.carForm
	display: flex
	flex-direction: column
	grid-gap: 10px
	padding: 20px
	background: white
	border-radius: 5px
	overflow: hidden
.twoItems
	display: flex
	grid-gap: 15px
	gap: 15px